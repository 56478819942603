import React, { useContext } from "react"

import Seo from "./seo"
import LanguageWrapper from "../wrappers/LanguageWrapper";
import LanguageContext from "../wrappers/contexts/LanguageContext";
import "./../assets/css/customUiKit.css"
import Nav from "./nav"

const Layout = ({ children, seo = {} }) => {
  return (
    <LanguageWrapper>
      <InsideTheLanguageWrapper seo={seo}>
        {children}
      </InsideTheLanguageWrapper>
    </LanguageWrapper>
  )
};

const InsideTheLanguageWrapper = ({ seo, children }) => {
  const { getTranslate } = useContext(LanguageContext);
  const { title } = seo;
  return (
    <>
      <Seo title={getTranslate(title)}/>
      <Nav/>
      <main>{children}</main>
    </>
  )
};

export default Layout
