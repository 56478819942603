import React, { useState } from 'react';
import LanguageContext from "./contexts/LanguageContext";
import { graphql, useStaticQuery } from "gatsby";


export default ({ children }) => {
  const data = useStaticQuery(generalTranslatesQuery);
  const strapiLanguages = data.allStrapiLanguage.edges;
  const [lang, setLang] = useState((typeof window !== "undefined" ? (localStorage.getItem("lang-blog-balearic") || "es") : "es"));

  const getTranslate = (objectToTranslate) => {
    if (Array.isArray(objectToTranslate)) {
      const translate = objectToTranslate.filter(tra => tra.language.lang === lang)[0];
      if (translate) {
        return translate.text || translate.content
      } else if (objectToTranslate[0]) {
        return objectToTranslate[0].text || objectToTranslate[0].content
      }
      return null
    } else {
      const optData = data.allStrapiGeneralTranslation.nodes;
      const found = optData.find(trans => trans.name === objectToTranslate);
      if (found) {
        const translates = found.text.translate;
        return getTranslate(translates) || objectToTranslate;
      }
      return objectToTranslate;
    }
  };

  const changeLang = (newLang) => {
    if (typeof window !== "undefined") {
      localStorage.setItem("lang-blog-balearic", newLang);
    }
    setLang(newLang)
  };

  const languages = [];
  strapiLanguages.forEach(item => languages.push(item.node.lang));

  return (
    <LanguageContext.Provider value={{ getTranslate, lang, languages, changeLang }} children={children}/>
  )
};

const generalTranslatesQuery = graphql`
  {
    allStrapiGeneralTranslation {
      nodes {
        name
        text {
          translate {
            language {
              lang
            }
            text
          }
        }
      }
    }
    allStrapiLanguage {
      edges {
        node {
          lang
        }
      }
    }
  }
`;


