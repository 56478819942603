/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import favicon from "./../images/favicon.ico"
import LanguageContext from "../wrappers/contexts/LanguageContext";

function SEO({ description, lang, meta, title }) {
  const { getTranslate } = useContext(LanguageContext);
  const { site, allStrapiSeo } = useStaticQuery(querySeo);

  const metaDescription = description || getTranslate(allStrapiSeo.edges[0].node.description.translate) || site.siteMetadata.description;
  const newTitle = title || getTranslate(allStrapiSeo.edges[0].node.title.translate);
  const icon = favicon;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={newTitle}
      titleTemplate={`%s | ${getTranslate(allStrapiSeo.edges[0].node.title.translate) || site.siteMetadata.title}`}
      link={[
        {
          rel: "icon",
          href: icon
        },
        {
          rel: "stylesheet",
          href: "https://fonts.googleapis.com/css?family=Staatliches",
        },
      ]}
      script={[
        {
          src:
            "https://cdnjs.cloudflare.com/ajax/libs/uikit/3.2.0/js/uikit.min.js",
        },
        {
          src:
            "https://cdn.jsdelivr.net/npm/uikit@3.2.3/dist/js/uikit-icons.min.js",
        },
        {
          src: "https://cdnjs.cloudflare.com/ajax/libs/uikit/3.2.0/js/uikit.js",
        },
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

const querySeo = graphql`
      query {
            allStrapiSeo {
          edges {
            node {
              description {
                translate {
                  text
                  language {
                    lang
                  }
                }
              }
              title {
                translate {
                  text
                  language {
                    lang
                  }
                }
              }
            }
          }
        }
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `;

export default SEO
